import React, { createRef } from "react";
import { format, parse as parseDate } from "date-fns";
import BoardListItem from "./BoardListItem";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonList,
  IonSpinner,
  IonButton,
  IonActionSheet,
  IonIcon,
} from "@ionic/react";
import * as API from "./../API.json";
import { fetchAPI } from "./../utils/APIUtil";
import "./BoardList.css";
import { BoardContent, BoardAttribute } from "./../models/Model.Board";
import {
  BoardType,
  BoardListState,
  BoardSortType,
  BoardFilterType,
} from "./../store/board/types";
import { getGlobal, GlobalKey, setGlobal } from "./../utils/GlobalUtil";
import { connect } from "react-redux";
import { RootState, actions } from "../store";
import { log, LogLevel } from "../utils/LogUtil";
import BoardListNoticeItem from "./BoardListNoticeItem";
import AnalyticsUtil from "./../utils/AnalyticsUtil";
import { configFromSession } from "@ionic/core";
import { TARGET } from "./../config.json";
import ABTestUtil, { ABTestFeature } from "../utils/ABTestUtil";
import { UserLevel, UserWorkType } from "../models/Model.User";
import BoardListSortButton from "./organisms/Board/BoardListSortButton";
import ToggleButton from "./atoms/ToggleButton/ToggleButton";
import { Absolute, Fixed, Static } from "./atoms/Layout";
import FloatingButton from "./atoms/FloatingButton";
import Button from "./atoms/Button";
import Text from "./atoms/Text";
import { COLOR_SYSTEM } from "./design/design-system";
import Icon from "./atoms/Icon";
import { size } from "lodash";
import BoardListUnionButton from "./organisms/Board/BoardListUnionButton";
import BoardListRecommends from "./BoardListRecommends";
import VisibilitySensor from "react-visibility-sensor";
import { sendKakaoInviteMessage } from "../utils/KakaoUtil";
import { MY_ADDRESS } from "./../config.json";
import { BannerInfo } from "../models/Model.Banner";
import StringUtil from "../utils/StringUtil";

type Props = RouteComponentProps<{}> &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    listType: BoardType;
    selected?: boolean;
    onHeaderHide?: (hide: boolean) => void;
    onMoveToRecommend?: () => void;
  };

type State = {
  needMoveToTop: boolean;
  showSortMenuPopover: boolean;
};

const windowAny: any = window;

const sortName: string[] = [
  "최신순",
  "최신순",
  "예전글 순",
  "좋아요 순",
  "스크랩 순",
  "나도궁금해요 순",
  "조회수 순",
  "답변수 순",
  "댓글수 순",
];

class BoardList extends React.Component<Props, State> {
  contents: BoardContent[] = null;
  checksum: number = 0;
  contentScroll = null;
  contentIonScroll = null;
  contentList = null;
  loadNewOnTop: boolean = false;
  lastScrollTop = 0;

  productId = 3;
  listEl = createRef<HTMLDivElement>();

  constructor(props: Props) {
    super(props);
    log(LogLevel.UI_LIFECYCLE, "BoardList:constructor");

    this.state = {
      showSortMenuPopover: false,
      needMoveToTop: false,
    };

    this.fetchData();
  }

  componentDidMount = async () => {
    log(LogLevel.UI_LIFECYCLE, "BoardList:componentDidMount");
    this.autoScroll();
  };

  autoScrollTryMax = 10;
  autoScrollTry = 0;

  autoScroll = async () => {
    // setTimeout(async () => {
    try {
      let scroll = getGlobal(GlobalKey.SCROLL + this.props.listType.toString());
      if (scroll) {
        if (this.contentIonScroll) {
          log(
            LogLevel.UI_EVENT,
            "BoardList:autoScroll contentIonScroll",
            BoardType[this.props.listType] || this.props.listType,
            this.contentScroll && scroll
          );
          await this.contentIonScroll.scrollToPoint(null, scroll);
          if (ABTestUtil.isTest(ABTestFeature.UI_MOVE_TO_TOP) && scroll > 500)
            this.setState({ needMoveToTop: true });
        } else if (this.contentScroll) {
          this.contentScroll.scrollTo(0, scroll);
          if (this.props.selected)
            setTimeout(() => {
              log(
                LogLevel.UI_EVENT,
                "BoardList:autoScroll done ",
                BoardType[this.props.listType] || this.props.listType,
                scroll
              );
              setGlobal(
                GlobalKey.SCROLL + this.props.listType.toString(),
                scroll
              );
            }, 0);

          log(
            LogLevel.UI_EVENT,
            "BoardList:autoScroll contentScroll",
            BoardType[this.props.listType] || this.props.listType,
            this.contentScroll && scroll
          );
          if (ABTestUtil.isTest(ABTestFeature.UI_MOVE_TO_TOP) && scroll > 500)
            this.setState({ needMoveToTop: true });
        } else {
          this.autoScrollTry++;
          if (this.autoScrollTry < this.autoScrollTryMax) {
            log(
              LogLevel.UI_EVENT,
              "BoardList:autoScroll Try ",
              BoardType[this.props.listType] || this.props.listType,
              this.autoScrollTry,
              this.contentScroll && scroll
            );
            setTimeout(() => this.autoScroll(), 10);
          } else {
            log(
              LogLevel.UI_EVENT,
              "BoardList:autoScroll Try FINISHED FAILED",
              BoardType[this.props.listType] || this.props.listType,
              this.autoScrollTry,
              this.contentScroll,
              scroll
            );
          }
        }
      } else {
        log(
          LogLevel.UI_EVENT,
          "BoardList:autoScroll no scroll ",
          BoardType[this.props.listType] || this.props.listType
        );
      }
    } catch (e) {
      log(LogLevel.UI_EXCEPTION, "BoardList:autoScroll failed", e);
      this.autoScrollTry++;
      if (this.autoScrollTry < this.autoScrollTryMax) {
        log(
          LogLevel.UI_EVENT,
          "BoardList:autoScroll Try ",
          this.props.listType,
          this.autoScrollTry,
          this.contentScroll,
          scroll
        );
        setTimeout(() => this.autoScroll(), 10);
      } else {
        log(
          LogLevel.UI_EVENT,
          "BoardList:autoScroll Try FINISHED FAILED",
          this.props.listType,
          this.autoScrollTry,
          this.contentScroll,
          scroll
        );
      }
    }
    // }, 100);
  };

  onMoveToTop = async () => {
    log(LogLevel.UI_EVENT, "BoardList:onMoveToTop");
    this.props.refreshBoards(this.props.listType);
    if (this.contentIonScroll) {
      await this.contentIonScroll.scrollToPoint(null, 0);
      this.setState({ needMoveToTop: false });
    } else if (this.contentScroll) {
      await this.contentScroll.scrollTo(0, 0);
      this.setState({ needMoveToTop: false });
    }
  };

  getContents = () => {
    return this.contents;
  };

  fetchData(props = this.props) {
    let thisBoardList: BoardListState = props.boards[props.listType];
    if (
      !thisBoardList ||
      (thisBoardList.needReload && !thisBoardList.loading)
    ) {
      log(
        LogLevel.UI_DATA_LOAD,
        "BoardList:fetchData LoadBoard",
        props.listType,
        thisBoardList,
        this.props.selected
      );
      props.loadBoard(props.listType);
    } else {
      log(
        LogLevel.UI_DATA_LOAD,
        "BoardList:fetchData ready",
        props.listType,
        thisBoardList
      );
      this.contents = thisBoardList.contents;
      this.checksum = thisBoardList.checksum;
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    log(
      LogLevel.UI_LIFECYCLE,
      // "BoardList:shouldComponentUpdate check",
      this.contents,
      this.props.boards[this.props.listType],
      nextProps.boards[this.props.listType]
    );
    if (
      nextProps.boards[this.props.listType].needReload ||
      this.checksum != nextProps.boards[this.props.listType].checksum ||
      (this.props.boards[this.props.listType].attribute.includes(
        BoardAttribute.UI_LIST_NOTICE
      ) &&
        this.props.boards[BoardType.LATEST_NOTICE].checksum !==
          nextProps.boards[BoardType.LATEST_NOTICE].checksum)
    ) {
      // log(LogLevel.UI_LIFECYCLE, "BoardList:shouldComponentUpdate board data update");
      return true;
    }

    if (
      nextProps.listType === this.props.listType &&
      nextState.needMoveToTop === this.state.needMoveToTop &&
      nextState.showSortMenuPopover === this.state.showSortMenuPopover
    ) {
      // log(LogLevel.UI_LIFECYCLE, "BoardList:shouldComponentUpdate skip");
      return false;
    }

    // log(LogLevel.UI_LIFECYCLE, "BoardList:shouldComponentUpdate update");
    return true;
  }

  componentWillUpdate(nextProps) {
    this.fetchData(nextProps);
    log(
      LogLevel.UI_LIFECYCLE,
      "BoardList.componentWillUpdate",
      this.props.listType
    );
  }

  componentDidUpdate() {
    log(
      LogLevel.UI_LIFECYCLE,
      "BoardList:componentDidUpdate",
      this.props.listType
    );
    this.fetchData();

    // setTimeout(() => {
    //   try{
    //     let scroll = getGlobal(GlobalKey.SCROLL+(this.props.listType).toString());
    //     log(LogLevel.UI_EVENT, "BoardList:componentDidUpdate:Scroll 2", (this.props.listType), this.contentScroll, scroll, (this.contentScroll && scroll));
    //     if(this.contentScroll && scroll){
    //       this.contentScroll.scrollToPoint(null, scroll);
    //     }
    //   }catch(e){
    //     log(LogLevel.UI_EXCEPTION, "BoardList:componentDidUpdate:Scroll failed", e)
    //   }
    // }, 200);
  }

  ionScroll = (event: CustomEvent) => {
    let target: any = event.target;
    if (
      !this.props.boards[this.props.listType].end &&
      !this.props.boards[this.props.listType].loading &&
      event.detail.scrollTop + target.clientHeight >
        this.contentList.clientHeight * 0.9
    ) {
      log(
        LogLevel.UI_EVENT,
        "BoardList:Scroll bottom loads more contents",
        event.detail.scrollTop,
        target.clientHeight,
        this.contentList.clientHeight
      );
      this.props.loadBoard(this.props.listType);
    }
    if (event.detail.scrollTop > 300 && !this.loadNewOnTop) {
      log(LogLevel.UI_EVENT, "BoardList:Scroll loadNewOnTop");
      this.loadNewOnTop = true;
    } else if (event.detail.scrollTop < 30 && this.loadNewOnTop) {
      log(LogLevel.UI_EVENT, "BoardList:Scroll updateBoard");
      this.loadNewOnTop = false;
      this.props.updateBoard(this.props.listType, true);
    }
    setGlobal(
      GlobalKey.SCROLL + this.props.listType.toString(),
      event.detail.scrollTop
    );
  };

  onScroll = (event) => {
    let target: any = event.target;

    if (!target || !this.contentList) return;

    // log(LogLevel.UI_EVENT, "BoardList:scroll", event.detail.scrollTop, target.clientHeight, this.contentList.clientHeight);
    if (
      !this.props.boards[this.props.listType].end &&
      !this.props.boards[this.props.listType].loading &&
      target.scrollTop + target.clientHeight >
        this.contentList.clientHeight * 0.9
    ) {
      log(
        LogLevel.UI_EVENT,
        "BoardList:Scroll bottom loads more contents",
        target.scrollTop,
        target.clientHeight,
        this.contentList.clientHeight
      );
      this.props.loadBoard(this.props.listType);
    }
    log(
      LogLevel.UI_EVENT,
      "BoardList:onScroll ",
      BoardType[this.props.listType] || this.props.listType,
      target.scrollTop
    );

    setGlobal(
      GlobalKey.SCROLL + this.props.listType.toString(),
      target.scrollTop
    );

    let board = this.props.boards[this.props.listType];
    if (board && !board.sort) {
      if (target.scrollTop > 300 && !this.loadNewOnTop) {
        log(LogLevel.UI_EVENT, "BoardList:Scroll loadNewOnTop");
        this.loadNewOnTop = true;
      } else if (target.scrollTop < 30 && this.loadNewOnTop) {
        log(LogLevel.UI_EVENT, "BoardList:Scroll updateBoard");
        this.loadNewOnTop = false;
        this.props.updateBoard(this.props.listType, true);
      }
    }

    if (ABTestUtil.isTest(ABTestFeature.UI_MOVE_TO_TOP))
      if (target.scrollTop > 500) {
        this.setState({ needMoveToTop: true });
      } else {
        this.setState({ needMoveToTop: false });
      }

    if (this.lastScrollTop < event.target.scrollTop) {
      // scroll down
      this.props.onHeaderHide(true);
      // this.props.headerRef.current.style.transform = `translateY(-108px)`;
      // this.props.mainRef.current.style.transform = `translateY(-108px)`;
      // this.props.mainRef.current.style.height = "calc(100vh - 48px)";
    } else {
      // scroll up
      this.props.onHeaderHide(false);

      // this.props.headerRef.current.style.transform = `translateY(0px)`;
      // this.props.mainRef.current.style.transform = `translateY(0px)`;
      // this.props.mainRef.current.style.height = "calc(100vh - 156px)";
    }

    this.lastScrollTop = event.target.scrollTop;
  };

  onClickItem = (content: BoardContent) => {
    log(LogLevel.UI_ACTION, "BoardList:onClickItem", content);
    let name = "";
    if (this.props.listType) name = BoardType[this.props.listType];

    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "SELECT_CONTENT",
      "게시물선택",
      { 게시물id: content.id, 게시판이름: name }
    );
    if (content.usersBizId) {
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "PARTNERS_SELECT_CONTENT",
        "약사파트너스_컨텐츠_선택",
        {
          게시물id: content.id,
          회사id: content.usersBizId,
          회사:
            this.props.partners && this.props.partners[content.usersBizId]
              ? this.props.partners[content.usersBizId].companyProfileName ||
                this.props.partners[content.usersBizId].companyName
              : "",
          콘텐츠: content.subject,
          from: `게시판/${name}`,
        }
      );
    }
  };

  onSort = (sort: BoardSortType) => {
    log(LogLevel.UI_ACTION, "BoardList:onSort", sort);

    let board = this.props.boards[this.props.listType];
    let newSort = sort;
    if (board.sort && board.sort == sort) newSort = BoardSortType.DEFAULT;

    this.props.sort(this.props.listType, newSort);

    let name = "";
    if (this.props.listType) name = BoardType[this.props.listType];
    AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "BOARD_SORT", "게시판정렬", {
      Sort: BoardSortType[newSort],
      게시판이름: name,
    });
  };

  onFilter = (filter: BoardFilterType) => () => {
    let board = this.props.boards[this.props.listType];
    let newFilter = filter;

    if (board.filter == newFilter) newFilter = BoardFilterType.NONE;

    this.props.filter(this.props.listType, newFilter);

    let name = "";
    if (this.props.listType) name = BoardType[this.props.listType];
    AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "BOARD_FILTER", "게시판필터", {
      Filter: BoardType[newFilter],
      게시판이름: name,
    });
  };

  onRefresh = () => {
    this.props.refreshBoards(this.props.listType);
  };

  onChangeUnion = (categories: BoardType[]) => {
    this.props.changeUnionBoard(categories, this.props.listType);

    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "BOARD_UNION",
      "관심게시판선택",
      {
        게시판: categories.join(","),
      }
    );
  };

  onBannerVisibleChange = (visible: boolean, banner: BannerInfo) => {
    log(
      LogLevel.UI_EVENT,
      "BoardListItem: onBannerVisibleChange",
      visible,
      banner
    );
    if (visible && banner.advertiserInfo) {
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "ADVERTISE_EXPOSURE",
        "광고노출",
        {
          type: "메인화면 배너",
          ...banner.advertiserInfo,
        }
      );
    }
  };

  onBannerClick = (banner: BannerInfo) => {
    log(LogLevel.UI_EVENT, "BoardListItem: onBannerClick", banner);
    if (banner.advertiserInfo) {
      if (banner.advertiserInfo.gsid) {
        fetchAPI(
          API.USER_CTA_RECORD,
          "",
          null,
          {
            gsid: banner.advertiserInfo.gsid,
            gstid: banner.advertiserInfo.gstid, // google sheet tab name
            shid: banner.advertiserInfo.shid, // slack hook url
            stid: banner.advertiserInfo.stid,
            stfields: banner.advertiserInfo.stfields,
            data:
              (banner.advertiserInfo.code || "") +
              "-" +
              (banner.advertiserInfo.productCode || "") +
              "-" +
              (banner.advertiserInfo.contentType || "") +
              "-" +
              (banner.advertiserInfo.contentId || ""), // additionalData
          },
          getGlobal(GlobalKey.TOKEN)
        );
      }
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "ADVERTISE_CLICK",
        "광고클릭",
        {
          type: "메인화면 배너",
          ...banner.advertiserInfo,
        }
      );
    }
    if (banner.targetUrl.startsWith("action://")) {
      switch (banner.targetUrl) {
        case "action://invite":
          AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "INVITE", "초대하기", {
            위치: "배너",
          });
          sendKakaoInviteMessage(this.props.me.name, this.props.me);
          break;
      }
    } else if (banner.targetUrl.startsWith("&&&")) {
      if (getGlobal(GlobalKey.OS) != "browser") {
        this.props.history.push(banner.targetUrl.substring(3));
      } else {
        window.open(
          banner.targetUrl.replace("&&&", MY_ADDRESS),
          getGlobal(GlobalKey.OS) == "browser" ? "_blank" : "_system"
        );
      }
    } else if (banner.targetUrl.startsWith(MY_ADDRESS)) {
      if (getGlobal(GlobalKey.OS) != "browser") {
        this.props.history.push(banner.targetUrl);
      } else {
        window.open(
          banner.targetUrl,
          getGlobal(GlobalKey.OS) == "browser" ? "_blank" : "_system"
        );
      }
    } else if (banner.targetUrl.startsWith("/")) {
      this.props.history.push(banner.targetUrl);
    } else if (
      banner.targetUrl.startsWith("tel") ||
      banner.targetUrl.startsWith("sms")
    ) {
      window.open(banner.targetUrl, "_system");
    } else
      window.open(
        banner.targetUrl,
        getGlobal(GlobalKey.OS) == "browser" ? "_blank" : "_system"
      );
  };

  render() {
    let board = this.props.boards[this.props.listType];
    let attribute = this.props.boards[this.props.listType]
      ? this.props.boards[this.props.listType].attribute
      : "";
    let filter = board && board.filter ? board.filter : BoardFilterType.NONE;
    let notice;
    let bannerView;
    let loading;
    log(
      LogLevel.UI_LIFECYCLE,
      "BoardList:render",
      board,
      attribute.toString(16)
    );

    if (!board || board.loading || (!board.end && !board.contents))
      loading = (
        <div className="board-list-waiting-container">
          <IonSpinner name="crescent" />
        </div>
      );

    if (
      board &&
      attribute.includes(BoardAttribute.UI_LIST_NOTICE) &&
      this.props.boards[BoardType.LATEST_NOTICE] &&
      this.props.boards[BoardType.LATEST_NOTICE].contents &&
      this.props.boards[BoardType.LATEST_NOTICE].contents.length > 0
    ) {
      let noticeContentId = this.props.boards[BoardType.LATEST_NOTICE]
        .contents[0].id;
      notice = <BoardListNoticeItem postingId={noticeContentId} />;
    }

    if (
      attribute.includes(BoardAttribute.UI_LIST_BANNER) &&
      this.props.me.workType != UserWorkType.UNDERGRADUATE
    ) {
      if (this.props.banners && this.props.banners.length > 0) {
        log(
          LogLevel.UI_LIFECYCLE,
          "BoardList:render banner ",
          this.props.banners
        );
        let banner = this.props.banners[0];
        if (banner.advertiserInfo)
          bannerView = (
            <VisibilitySensor
              onChange={(visible) =>
                this.onBannerVisibleChange(visible, banner)
              }
            >
              <img
                className="boardlistitem-banner"
                src={StringUtil.convertFilePath(banner.imageUrl)}
                onClick={() => this.onBannerClick(banner)}
              />
            </VisibilitySensor>
          );
        else
          bannerView = (
            <img
              className="boardlistitem-banner"
              src={StringUtil.convertFilePath(banner.imageUrl)}
              onClick={() => this.onBannerClick(banner)}
            />
          );
      }
    }

    let toolbar;
    let toolbars = [];
    let popoverSortMenu = [];

    let index = 0;
    // if(ABTestUtil.isTest()){

    // if (attribute.includes(BoardAttribute.UI_LIST_FILTER) || attribute.includes(BoardAttribute.UI_LIST_SORT))
    //   toolbars.push(<div key={(index++).toString()} className="common-flex-grow" />);
    if (
      this.props.me.workType != UserWorkType.UNDERGRADUATE &&
      (this.props.listType == BoardType.UNION_TAB ||
        this.props.listType == BoardType.RECOMMEND)
    ) {
      toolbars.push(
        <BoardListUnionButton
          key="union"
          onChangeUnion={this.onChangeUnion}
          listType={this.props.listType}
        />
      );
    }

    toolbars.push(<div key="empty" className="common-flex-grow" />);

    if (attribute.includes(BoardAttribute.UI_LIST_SORT)) {
      let sort = board && board.sort ? board.sort : 0;
      if (sort != BoardSortType.TIME_DESC) {
        popoverSortMenu.push({
          text: sortName[BoardSortType.TIME_DESC],
          cssClass: "common-menu-normal",
          handler: () => {
            this.onSort(BoardSortType.TIME_DESC);
          },
        });
      }

      // if(this.props.me.level >= UserLevel.MANAGER && sort != BoardSortType.TIME_ASC){
      if (sort != BoardSortType.TIME_ASC) {
        popoverSortMenu.push({
          text: sortName[BoardSortType.TIME_ASC],
          cssClass: "common-menu-normal",
          handler: () => {
            this.onSort(BoardSortType.TIME_ASC);
          },
        });
      }

      if (filter == BoardFilterType.UNANSWERED) {
        if (sort != BoardSortType.METOO) {
          popoverSortMenu.push({
            text: sortName[BoardSortType.METOO],
            cssClass: "common-menu-normal",
            handler: () => {
              this.onSort(BoardSortType.METOO);
            },
          });
        }
      } else {
        if (sort != BoardSortType.LIKE) {
          popoverSortMenu.push({
            text: sortName[BoardSortType.LIKE],
            cssClass: "common-menu-normal",
            handler: () => {
              this.onSort(BoardSortType.LIKE);
            },
          });
        }
      }

      if (sort != BoardSortType.VIEW) {
        popoverSortMenu.push({
          text: sortName[BoardSortType.VIEW],
          cssClass: "common-menu-normal",
          handler: () => {
            this.onSort(BoardSortType.VIEW);
          },
        });
      }

      if (
        filter == BoardFilterType.UNANSWERED &&
        sort != BoardSortType.REPLY &&
        attribute.includes(BoardAttribute.CAN_REPLY)
      ) {
        popoverSortMenu.push({
          text: sortName[BoardSortType.REPLY],
          cssClass: "common-menu-normal",
          handler: () => {
            this.onSort(BoardSortType.REPLY);
          },
        });
      }

      if (
        sort != BoardSortType.COMMENT &&
        attribute.includes(BoardAttribute.CAN_COMMENT_ON_POST)
      ) {
        popoverSortMenu.push({
          text: sortName[BoardSortType.COMMENT],
          cssClass: "common-menu-normal",
          handler: () => {
            this.onSort(BoardSortType.COMMENT);
          },
        });
      }

      if (popoverSortMenu.length > 0) {
        toolbars.push(
          // <IonButton
          //   key={(index++).toString()}
          //   color="board-list-toolbar-button"
          //   fill="clear"
          //   onClick={() => {
          //     this.setState({ showSortMenuPopover: true });
          //   }}
          // >
          //   <IonLabel>{sortName[sort]}</IonLabel>
          //   <IonIcon class="board-list-toolbar-button-icon" mode="md" name="arrow-dropdown" />
          // </IonButton>
          <BoardListSortButton
            key={(index++).toString()}
            tab={this.props.boardList.findIndex(
              (board) => board === this.props.listType
            )}
            boardId={this.props.listType}
          />
        );
      }
    }

    if (attribute.includes(BoardAttribute.UI_LIST_FILTER)) {
      toolbars.push(
        // <div
        //   key={(index++).toString()}
        //   className={
        //     "board-list-toolbar-toggle-button" +
        //     (filter == BoardFilterType.UNREAD ? " board-list-toolbar-toggle-button-active" : "")
        //   }
        //   onClick={() => this.onFilter(BoardFilterType.UNREAD)}
        // >
        //   안읽은글
        // </div>
        <ToggleButton
          key={(index++).toString()}
          color="Skyblue"
          size="Small"
          variant="Ghost"
          type="IconWithText"
          icon="Check Circle"
          left
          active={filter === BoardFilterType.UNREAD}
          onClick={this.onFilter(BoardFilterType.UNREAD)}
        >
          안읽은글
        </ToggleButton>
      );

      if (attribute.includes(BoardAttribute.CAN_REPLY)) {
        toolbars.push(
          // <div
          //   key={(index++).toString()}
          //   className={
          //     "board-list-toolbar-toggle-button" +
          //     (filter == BoardFilterType.UNANSWERED ? " board-list-toolbar-toggle-button-active" : "")
          //   }
          //   onClick={() => this.onFilter(BoardFilterType.UNANSWERED)}
          // >
          //   미답변
          // </div>
          <ToggleButton
            key={(index++).toString()}
            color="Skyblue"
            size="Small"
            variant="Ghost"
            type="IconWithText"
            icon="Check Circle"
            active={filter === BoardFilterType.UNANSWERED}
            left
            onClick={this.onFilter(BoardFilterType.UNANSWERED)}
          >
            미답변
          </ToggleButton>
        );
      }
    }

    // if (attribute.includes(BoardAttribute.UI_LIST_FILTER) || attribute.includes(BoardAttribute.UI_LIST_SORT))
    //   toolbars.push(
    //     <IonButton key={(index++).toString()} color="board-list-toolbar-button" fill="clear" onClick={this.onRefresh}>
    //       <IonIcon class="board-list-toolbar-button-icon" mode="md" name="refresh" />
    //     </IonButton>
    //   );

    if (toolbars.length > 0)
      toolbar = (
        <Static
          customStyle={{
            padding: "12px 20px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          {toolbars}
        </Static>
      );

    // let fabContent = <IonIcon class="boards-fab-icon" src={composeIcon}/>;
    let fab;
    fab = (
      <Absolute
        bottom="16px"
        right="20px"
        zIndex={100}
        customStyle={{
          display: "flex",
          flexDirection: "column",
          gap: "12px",
          transform: "translateZ(0)",
        }}
      >
        <FloatingButton
          color="Tertiary"
          icon={
            ABTestUtil.isTest(ABTestFeature.UI_MOVE_TO_TOP) &&
            this.state.needMoveToTop
              ? "ArrowUpLarge"
              : "Refresh"
          }
          onClick={this.onMoveToTop}
          size="Large"
          type="Icon"
        />
      </Absolute>
      // <Absolute
      //   bottom="16px"
      //   right="20px"
      //   zIndex={5}
      //   customStyle={{
      //     display: "flex",
      //     flexDirection: "column",
      //     gap: "12px",
      //     transform: "translateZ(0)",
      //   }}
      // >
      //   {ABTestUtil.isTest(ABTestFeature.UI_MOVE_TO_TOP) && this.state.needMoveToTop ? (
      //     <FloatingButton size="Medium" type="Icon" color="Tertiary" icon="ArrowUpLarge" onClick={this.onMoveToTop} />
      //   ) : (
      //     <FloatingButton size="Medium" type="Icon" color="Tertiary" icon="Refresh" onClick={this.onRefresh} />
      //   )}
      // </Absolute>
      // <IonButton color="board-list-fab" onClick={this.onMoveToTop}>
      //   <IonIcon class="board-list-fab-icon" mode="ios" name="arrow-up" />
      // </IonButton>
    );

    if (board && board.contents) {
      // if(false){
      log(
        LogLevel.NONE,
        "BoardList:render",
        board.contents.length,
        board.contents,
        this.props.listType,
        this.props.listType == BoardType.UNION_TAB
      );
      return (
        // <IonContent
        //   class="common-content common-scroll"
        //   ref={(ref)=>{this.contentIonScroll=ref}}
        //   scrollEvents={true}
        //   onIonScroll={this.ionScroll}
        // >
        <div className="common-content common-position-relative">
          {fab}
          <div
            className="common-content common-scroll"
            ref={(ref) => {
              this.contentScroll = ref;
            }}
            onScroll={this.onScroll}
          >
            <IonList
              ref={(ref) => (this.contentList = ref)}
              class="boardlist"
              mode="md"
            >
              {/* { this.state.postings.map((item, index: number) => ( */}
              {bannerView}
              {notice}
              {this.props.listType == BoardType.UNION_TAB && (
                <BoardListRecommends
                  onMoveToRecommend={this.props.onMoveToRecommend}
                />
              )}
              {toolbar}
              <Static
                customStyle={{
                  paddingTop: toolbars.length === 0 ? "12px" : "0px",
                  paddingBottom: "140px",
                  transform: "translateZ(0)",
                  "& > * + *": {
                    marginTop: "12px",
                  },
                }}
              >
                {board.contents.map((item, index: number) => (
                  <BoardListItem
                    {...this.props}
                    attribute={attribute}
                    filter={filter}
                    key={index.toString()}
                    index={index}
                    id={item.id}
                    onClick={this.onClickItem}
                    onBannerClick={this.onBannerClick}
                    onBannerVisibleChange={this.onBannerVisibleChange}
                    // posting={item}
                  />
                ))}
              </Static>
              {loading}
            </IonList>
            <IonActionSheet
              mode="ios"
              children
              isOpen={this.state.showSortMenuPopover}
              onDidDismiss={() =>
                this.setState(() => ({ showSortMenuPopover: false }))
              }
              buttons={popoverSortMenu}
              backdropDismiss={true}
            />
          </div>
        </div>
      );
    } else
      return (
        <div
          className="common-content common-scroll board-list-background"
          ref={(ref) => {
            this.contentScroll = ref;
          }}
          onScroll={this.onScroll}
        >
          {toolbar}
          {loading}
        </div>
      );
  }
}

const mapStateToProps = (state: RootState) => ({
  boards: state.board.boards,
  boardList: state.board.boardList,
  banners: state.banner.banners,
  me: state.user.me,
  partners: state.allinone.partners,
});

const mapDispatchToProps = {
  // logOutUser: () => actions.user.logout(),
  // refreshBoards: () => actions.board.refreshBoards(),
  loadBoard: (board: BoardType) => actions.board.getBoard(board, 15),
  updateBoard: (board: BoardType, force: boolean) =>
    actions.board.getBoardUpdates(board, force),
  changeUnionBoard: (categories: BoardType[], tabId) =>
    actions.board.changeUnionBoard(categories, tabId),
  refreshBoards: (board: BoardType) => actions.board.refreshBoards(board),
  sort: (board: BoardType, sort: BoardSortType) =>
    actions.board.sortBoard(board, sort),
  filter: (board: BoardType, filter: number) =>
    actions.board.filterBoard(board, filter),
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BoardList)
);
