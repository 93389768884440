import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Button from "../../atoms/Button";
import TabBar from "../../molecules/TabBar";
import FloatingButton from "../../atoms/FloatingButton";
import { COLOR_SYSTEM } from "../../design/design-system";
import JobPostCard from "../../organisms/JobPostCard";
import TopAppBar from "../../molecules/TopAppBar/TopAppBar";
import { JobListState, JobListType } from "../../../store/jobpost/types";
import LocationBottomSheetModal, {
  Tab,
} from "../../organisms/LocationBottomSheetModal/LocationBottomSheetModal";
import {
  getPayString,
  JobOfferInfo,
  JobOfferName,
  JobOfferType,
  PostSort,
} from "../../../models/Model.JobPost";
import { log, LogLevel } from "../../../utils/LogUtil";
import {
  CompanyType,
  UserInfo,
  UserWorkType,
  UserWorkTypeName,
} from "../../../models/Model.User";
import Text from "../../atoms/Text";
import Icon from "../../../component/atom/Icon";
import Popup from "../../molecules/Popup";
import { withRouter, RouteComponentProps } from "react-router-dom";
import {
  Filter,
  FixedButton,
  JobPostListWrapper,
  Main,
  NoPosts,
  PostList,
  PullUpErrorModalFooter,
  PullUpErrorModalMain,
  PullUpModalFooter,
  PullUpModalMain,
  Header,
  TopAppBarRight,
  NewChatAlertBox,
  RecruitTemplateListTitleRow,
} from "./style";
import BottomSheet from "../../molecules/BottomSheet/BottomSheet";
import {
  displayTabbar,
  hideTabbar,
  isMobile,
} from "../../../utils/JobPostUtil";
import Badge from "../../atoms/Badge/Badge";
import { getGlobal, setGlobal } from "../../../utils/GlobalUtil";
import ABTestUtil, { ABTestFeature } from "../../../utils/ABTestUtil";
import ChannelTalkService from "../../../utils/ChannelTalkService";
import { throttle } from "lodash";
import Spinner from "../../atoms/Spinner";
import AnalyticsUtil from "../../../utils/AnalyticsUtil";
import FinishJobPostPopup from "../../organisms/JobPostPopup/FinishJobPostPopup";
import Switch from "../../atoms/Switch/Switch";
import ListSortFilterBottomSheet from "../../organisms/JobPostBottomSheet/ListSortFilterBottomSheet";
import RequestRaisePopup from "../../organisms/JobPostPopup/RequestRaisePopup";
import WorkTypeSelectBottomSheet from "../../organisms/JobPostBottomSheet/WorkTypeSelectBottomSheet";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { UIServiceType } from "../../../store/ui/types";
import { getOS } from "../../../utils/DeviceUtil";
import PremiumCard from "../../organisms/PremiumCard";
import ToggleButton from "../../atoms/ToggleButton/ToggleButton";
import StringUtil from "../../../utils/StringUtil";
import { on } from "cluster";
import AdsUtil from "../../../utils/AdsUtil";

export interface JobPostListProps {
  me: UserInfo;
  /** 모든 공고 리스트 */
  jobPostsSearched: JobListState;
  jobPostsMyLike: JobListState;
  jobPostsMy: JobListState;
  jobPostsUnread: JobListState;
  jobPostsPharmacy: JobListState;
  jobPostsBiz: JobListState;
  jobPostsPremium: JobListState;
  onGetMorePosts?: (type: JobListType) => void;
  /** 개국약사 or 근무약사인지 체크 */
  isOwner?: boolean;
  isHospital?: boolean;
  offers?: any; //{offerID: JobOfferInfo}
  onSearchChanged?: (options: any, type: JobListType) => void;
  onRaise?: (offer: JobOfferInfo) => void;
  onAlimMessage?: (offer: JobOfferInfo) => void;
  onPrepareFinalize?: (offer: JobOfferInfo) => void;
  onScrap?: (offer: JobOfferInfo) => void;
  onUnscrap?: (offer: JobOfferInfo) => void;
  onSelect?: (offer: JobOfferInfo, isBiz?: boolean) => void;
  onPremium?: (offer: JobOfferInfo) => void;
  onRefresh?: (list: JobListType) => void;
  onSetting?: () => void;
  onChat?: () => void;
  // hasChat?: number;
  chatUnreadMessageCount?: number;
  lastVisited?: number;
  onGetKeywords?: () => Promise<any>;
  onAddKeyword?: (keyword: string) => Promise<void>;
  onDeleteKeyword?: (keyword: string) => Promise<void>;
  getCurrentFiltered: (
    filter: "companyType" | "type" | "location",
    selector: any
  ) => any;
  getOptions: (postType: JobListType) => any;
  filteredCompanyType: CompanyType | null;
  onGotoBiz?: () => void;
  onMessageBiz?: () => void;
}

const JobPostList: React.FC<JobPostListProps & RouteComponentProps> = ({
  history,
  me,
  jobPostsSearched,
  jobPostsMyLike,
  jobPostsMy,
  jobPostsPharmacy,
  jobPostsBiz,
  jobPostsPremium,
  onGetMorePosts,
  offers,
  isOwner,
  isHospital,
  onSearchChanged,
  onRaise,
  onAlimMessage,
  onGotoBiz,
  onMessageBiz,
  onPrepareFinalize,
  onScrap,
  onUnscrap,
  onSelect,
  onPremium,
  onRefresh,
  onSetting,
  onChat,
  // hasChat,
  chatUnreadMessageCount,
  lastVisited,
  onGetKeywords,
  onAddKeyword,
  onDeleteKeyword,
  getCurrentFiltered,
  getOptions,
  filteredCompanyType,
}) => {
  try {
    let timeoutId = null;
    let scrollTopTimer = null;
    const backKeyControl = useSelector(
      (state: RootState) => state.ui.services[UIServiceType.BACK_CONTROLLER]
    );

    // 검색
    const initialHeight = window.innerHeight;
    const [keyboard, setKeyboard] = useState(false);
    const [onSearching, setOnSearching] = useState(false);
    const [keywordList, setKeywordList] = useState([]);
    const [keywordValue, setKeywordValue] = useState(
      getGlobal("keyword") || ""
    );
    const [postType, setPostType] = useState<JobListType>(() => {
      if (getGlobal("jobpost-post-type")) return getGlobal("jobpost-post-type");

      if (
        me.workType !== UserWorkType.PHARMACY_OWNER &&
        jobPostsPremium.gold &&
        (jobPostsPremium.gold.length > 0 ||
          jobPostsPremium.silver.length > 0 ||
          jobPostsPremium.bronze.length > 0)
      ) {
        return JobListType.PREMIUM;
      }

      if (me.workType === UserWorkType.PHARMACY_OWNER) return JobListType.MY;

      return JobListType.PHARMACY;
    });
    const [currentTab, setCurrentTab] = useState<Tab>(null);
    const [passed, setPassed] = useState<number[]>([]);
    const [unreadOnly, setUnreadOnly] = useState(
      jobPostsPharmacy &&
        jobPostsPharmacy.options &&
        jobPostsPharmacy.options.unreadOnly
        ? true
        : false
    );

    // Popup
    const [raisePopup, setRaisePopup] = useState(false);
    const [raiseErrorPopup, setRaiseErrorPopup] = useState(false);
    const [finishPopup, setFinishPopup] = useState(false);
    const [noAddressPopup, setNoAddressPopup] = useState(false);
    const [registerPostErrorPopup, setRegisterPostErrorPopup] = useState(false);
    const [registerPostHospitalPopup, setRegisterPostHospitalPopup] = useState(
      false
    );
    const [requestRaisePopup, setRequestRaisePopup] = useState(false);
    const [workTypeSheet, setWorkTypeSheet] = useState(false);
    const [filterBottomSheet, setFilterBottomSheet] = useState(false);
    const [sortFilterBottomSheet, setSortFilterBottomSheet] = useState(false);

    const postListRef = useRef<HTMLElement>();
    const selectedOffer = useRef<JobOfferInfo | null>();

    const scrollPosition = useRef({
      premium: getGlobal("premium-scroll-position") || 0,
      pharmacy: getGlobal("pharmacy-scroll-position") || 0,
      biz: getGlobal("biz-scroll-position") || 0,
      searched: getGlobal("searched-scroll-position") || 0,
      like: getGlobal("like-scroll-position") || 0,
      me: getGlobal("me-scroll-position") || 0,
    });

    const selectedWorkType = useRef(null);

    const getSelectedSort = useCallback(() => {
      if (postType === JobListType.SEARCHED) {
        return jobPostsSearched.options.order === "raised"
          ? "최신순"
          : "거리순";
      }
      if (postType === JobListType.PHARMACY) {
        return jobPostsPharmacy.options.order === "raised"
          ? "최신순"
          : "거리순";
      }
      if (postType === JobListType.BIZ) {
        return jobPostsBiz.options.order === "raised" ? "최신순" : "거리순";
      }
    }, [postType, jobPostsSearched, jobPostsPharmacy, jobPostsBiz]);

    const handleTopClick = () => {
      postListRef.current.scrollTo({ top: 0, behavior: "smooth" });
      onRefresh(postType);
    };

    const handleChannelTalk = (e) => {
      e.stopPropagation();
      log(
        LogLevel.UI_EVENT,
        "JobPostList:handleChannelTalk ",
        ABTestUtil.isTest(ABTestFeature.UI_JOB_POST_CHANNEL_TALK)
      );
      if (ABTestUtil.isTest(ABTestFeature.UI_JOB_POST_CHANNEL_TALK))
        ChannelTalkService.show();
    };

    const goToPost = (post: JobOfferInfo, isBiz: boolean = false) => () => {
      setGlobal("recent-read-post", post.id);
      saveCurrentInfo();
      onSelect(post, isBiz);
    };

    const saveCurrentInfo = () => {
      // 검색어
      if (postType === JobListType.SEARCHED) {
        setGlobal("keyword", keywordValue);
        setGlobal("searched-scroll-position", scrollPosition.current.searched);
      }

      // 현재 내가 방문한 탭
      setGlobal("jobpost-post-type", postType);
      log(
        LogLevel.UI_EVENT,
        "JobPostList:saveCurrentInfo ",
        scrollPosition.current
      );

      // scroll height
      setGlobal("premium-scroll-position", scrollPosition.current.premium);
      setGlobal("pharmacy-scroll-position", scrollPosition.current.pharmacy);
      setGlobal("biz-scroll-position", scrollPosition.current.biz);
      setGlobal("me-scroll-position", scrollPosition.current.me);
      setGlobal("like-scroll-position", scrollPosition.current.like);
    };

    const renderPosts = () => {
      if (
        (postType === JobListType.PHARMACY &&
          (!jobPostsPharmacy || jobPostsPharmacy.needReload)) ||
        (postType === JobListType.BIZ &&
          (!jobPostsBiz || jobPostsBiz.needReload)) ||
        (me.workType !== UserWorkType.PHARMACY_OWNER &&
          postType === JobListType.MY_LIKE &&
          (!jobPostsMyLike || jobPostsMyLike.needReload)) ||
        (me.workType === UserWorkType.PHARMACY_OWNER &&
          postType === JobListType.MY &&
          (!jobPostsMy || jobPostsMy.needReload)) ||
        (postType === JobListType.SEARCHED &&
          (!jobPostsSearched || jobPostsSearched.needReload)) ||
        (me.workType !== UserWorkType.PHARMACY_OWNER &&
          postType === JobListType.PREMIUM &&
          !jobPostsPremium.gold)
      )
        return <Spinner innerColor={COLOR_SYSTEM.get("Gray")[10]} />;

      const posts = [];

      switch (postType) {
        case JobListType.SEARCHED:
          if (jobPostsSearched.items.length) {
            jobPostsSearched.items.forEach((item: JobOfferInfo) => {
              const offer = offers[item.id];

              let isNew: boolean = false;
              const date = new Date(offer.createdAt);
              if (date) {
                let value = date.valueOf();
                isNew =
                  value > lastVisited &&
                  Date.now() - value <= 2 * 24 * 60 * 60 * 1000;
                log(
                  LogLevel.UI_LIFECYCLE,
                  "JobPostList: new check ",
                  offer.createdAt,
                  date,
                  value,
                  lastVisited,
                  isNew
                );
              }

              posts.push(
                <JobPostCard
                  key={item.id}
                  me={me}
                  post={offer}
                  isNew={isNew}
                  isOwner={isOwner}
                  pay={getPayString(offer)}
                  cardType={me.id === offer.ownerId ? "Me" : "All"}
                  onClick={goToPost(item, offer.companyType > 0)}
                  onRaise={handleRaiseClick(offer)}
                  onAlimMessage={() => {
                    onAlimMessage(offer);
                  }}
                  onFinalize={handleFinalizeClick(offer, JobListType.ALL)}
                  onScrap={handleScrap(offer)}
                  onUnscrap={handleUnscrap(offer)}
                  onPremium={handlePremium(offer)}
                />
              );
            });
          } else {
            return (
              <div className="searched-result__no-result-container">
                <div className="no-result__title">
                  <Text
                    element="h4"
                    textType="H4"
                    color={COLOR_SYSTEM.get("Gray")[800]}
                  >
                    검색 결과가 없어요.
                  </Text>
                </div>
                <div>
                  <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[400]}>
                    검색할 수 있는 항목은 다음과 같습니다.
                  </Text>
                  <ul className="no-result__example-list">
                    <li className="no-result__example-item">
                      <Text
                        textType="Body1"
                        color={COLOR_SYSTEM.get("Gray")[400]}
                      >
                        제목
                      </Text>
                    </li>
                    <li className="no-result__example-item">
                      <Text
                        textType="Body1"
                        color={COLOR_SYSTEM.get("Gray")[400]}
                      >
                        약국 설명
                      </Text>
                    </li>
                    <li className="no-result__example-item">
                      <Text
                        textType="Body1"
                        color={COLOR_SYSTEM.get("Gray")[400]}
                      >
                        전산 S/W
                      </Text>
                    </li>
                    <li className="no-result__example-item">
                      <Text
                        textType="Body1"
                        color={COLOR_SYSTEM.get("Gray")[400]}
                      >
                        자동화기기
                      </Text>
                    </li>
                    <li className="no-result__example-item">
                      <Text
                        textType="Body1"
                        color={COLOR_SYSTEM.get("Gray")[400]}
                      >
                        복리후생
                      </Text>
                    </li>
                  </ul>
                </div>
              </div>
            );
          }
          break;
        case JobListType.MY_LIKE:
          if (
            jobPostsMyLike &&
            jobPostsMyLike.items &&
            jobPostsMyLike.items.length
          ) {
            jobPostsMyLike.items.forEach((item: JobOfferInfo) => {
              const offer = offers[item.id];

              posts.push(
                <JobPostCard
                  key={item.id}
                  me={me}
                  post={offer}
                  isOwner={isOwner}
                  pay={getPayString(offer)}
                  cardType={"Interest"}
                  onClick={goToPost(item, offer.companyType > 0)}
                  onRaise={handleRaiseClick(offer)}
                  onAlimMessage={() => onAlimMessage(offer)}
                  onFinalize={handleFinalizeClick(offer, JobListType.MY_LIKE)}
                  onScrap={handleScrap(offer)}
                  onUnscrap={handleUnscrap(offer)}
                  onPremium={handlePremium(offer)}
                />
              );
            });
          } else {
            return (
              <NoPosts postType={postType}>
                <Text
                  element="h2"
                  textType="H2"
                  color={COLOR_SYSTEM.get("Gray")[400]}
                >
                  관심 공고가 없어요
                </Text>
                <Text textType="Headline" color={COLOR_SYSTEM.get("Gray")[400]}>
                  공고 상단의{" "}
                  <Icon
                    width={24}
                    height={24}
                    name={"BookmarkFilled"}
                    fill={COLOR_SYSTEM.get("Gray")[100]}
                  />{" "}
                  을 눌러 <br />
                  관심공고를 추가해보세요
                </Text>
              </NoPosts>
            );
          }
          break;
        case JobListType.MY:
          if (jobPostsMy && jobPostsMy.items && jobPostsMy.items.length) {
            jobPostsMy.items.forEach((item: JobOfferInfo) => {
              const offer = offers[item.id];

              posts.push(
                <JobPostCard
                  key={item.id}
                  me={me}
                  post={offer}
                  isOwner={isOwner}
                  pay={getPayString(offer)}
                  cardType={"Me"}
                  onClick={goToPost(item)}
                  onRaise={handleRaiseClick(offer)}
                  onAlimMessage={() => onAlimMessage(offer)}
                  onFinalize={handleFinalizeClick(offer, JobListType.MY)}
                  onPremium={handlePremium(offer)}
                />
              );
            });
          } else {
            return (
              <NoPosts postType={postType}>
                <Text
                  element="h2"
                  textType="H2"
                  color={COLOR_SYSTEM.get("Gray")[400]}
                >
                  등록한 공고가 없어요
                </Text>
                <Text textType="Headline" color={COLOR_SYSTEM.get("Gray")[400]}>
                  아래 공고 등록하기를 눌러
                  <br />
                  공고를 등록해주세요
                </Text>
              </NoPosts>
            );
          }
          break;
        case JobListType.PHARMACY:
          if (jobPostsPharmacy && jobPostsPharmacy.items.length > 0) {
            jobPostsPharmacy.items.forEach((item: JobOfferInfo) => {
              const offer = offers[item.id];

              let isNew: boolean = false;
              const date = new Date(offer.createdAt);
              if (date) {
                let value = date.valueOf();
                isNew =
                  value > lastVisited &&
                  Date.now() - value <= 2 * 24 * 60 * 60 * 1000;
              }

              posts.push(
                <JobPostCard
                  key={item.id}
                  me={me}
                  post={offer}
                  isNew={isNew}
                  isOwner={isOwner}
                  isRead={
                    jobPostsPharmacy.options.unreadOnly &&
                    getGlobal("recent-read-post") === item.id
                  }
                  pay={getPayString(offer)}
                  cardType={me.id === offer.ownerId ? "Me" : "All"}
                  onClick={goToPost(item)}
                  onRaise={handleRaiseClick(offer)}
                  onAlimMessage={() => {
                    onAlimMessage(offer);
                  }}
                  onFinalize={handleFinalizeClick(offer, JobListType.ALL)}
                  onScrap={handleScrap(offer)}
                  onUnscrap={handleUnscrap(offer)}
                  onPremium={handlePremium(offer)}
                />
              );
            });
          } else {
            return (
              <NoPosts postType={postType}>
                <Text
                  element="h2"
                  textType="H2"
                  color={COLOR_SYSTEM.get("Gray")[400]}
                >
                  해당 지역에 진행중인
                  <br />
                  구인 공고가 없어요
                </Text>
              </NoPosts>
            );
          }
          break;
        case JobListType.BIZ:
          if (jobPostsBiz && jobPostsBiz.items.length > 0) {
            jobPostsBiz.items.forEach((item: JobOfferInfo) => {
              const offer = offers[item.id];

              let isNew: boolean = false;
              const date = new Date(offer.createdAt);
              if (date) {
                let value = date.valueOf();
                isNew =
                  value > lastVisited &&
                  Date.now() - value <= 2 * 24 * 60 * 60 * 1000;
              }

              posts.push(
                <JobPostCard
                  key={item.id}
                  me={me}
                  post={offer}
                  isNew={isNew}
                  isOwner={isOwner}
                  isRead={
                    jobPostsBiz.options.unreadOnly &&
                    getGlobal("recent-read-post") === item.id
                  }
                  pay={getPayString(offer)}
                  cardType={"Biz"}
                  onClick={goToPost(item, true)}
                  onRaise={handleRaiseClick(offer)}
                  onAlimMessage={() => {
                    onAlimMessage(offer);
                  }}
                  onFinalize={handleFinalizeClick(offer, JobListType.ALL)}
                  onScrap={handleScrap(offer)}
                  onUnscrap={handleUnscrap(offer)}
                  onPremium={handlePremium(offer)}
                />
              );
            });
          } else {
            return (
              <NoPosts postType={postType}>
                <Text
                  element="h2"
                  textType="H2"
                  color={COLOR_SYSTEM.get("Gray")[400]}
                >
                  해당 지역에 진행중인
                  <br />
                  구인 공고가 없어요
                </Text>
              </NoPosts>
            );
          }
          break;
        case JobListType.PREMIUM:
          if (jobPostsPremium.gold.length) {
            posts.push(
              <RecruitTemplateListTitleRow key={"GOLD"}>
                <img
                  src={`${process.env.PUBLIC_URL}/images/list_gold_medal.png`}
                  alt="product 이미지"
                  width={17}
                  height={20}
                />
                <Text textType="H4" color={COLOR_SYSTEM.get("Gray")[700]}>
                  Gold
                </Text>
              </RecruitTemplateListTitleRow>
            );
          }

          if (jobPostsPremium && jobPostsPremium.gold.length > 0) {
            jobPostsPremium.gold.forEach((jobPost: JobOfferInfo) => {
              posts.push(
                <PremiumCard
                  key={jobPost.id}
                  post={jobPost}
                  onClick={goToPost(jobPost, true)}
                />
              );
            });
          }

          if (jobPostsPremium.silver.length) {
            posts.push(
              <RecruitTemplateListTitleRow
                key={"SILVER"}
                className={jobPostsPremium.gold.length ? "mg-top" : ""}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/images/list_silver_medal.png`}
                  alt="product 이미지"
                  width={17}
                  height={20}
                />
                <Text textType="H4" color={COLOR_SYSTEM.get("Gray")[700]}>
                  Silver
                </Text>
              </RecruitTemplateListTitleRow>
            );
          }

          if (jobPostsPremium && jobPostsPremium.silver.length > 0) {
            jobPostsPremium.silver.forEach((jobPost: JobOfferInfo) => {
              posts.push(
                <PremiumCard
                  key={jobPost.id}
                  post={jobPost}
                  onClick={goToPost(jobPost, true)}
                />
              );
            });
          }

          if (jobPostsPremium.bronze.length) {
            posts.push(
              <RecruitTemplateListTitleRow
                key={"BRONZE"}
                className={
                  jobPostsPremium.gold.length || jobPostsPremium.silver.length
                    ? "mg-top"
                    : ""
                }
              >
                <img
                  src={`${process.env.PUBLIC_URL}/images/list_bronze_medal.png`}
                  alt="product 이미지"
                  width={17}
                  height={20}
                />
                <Text textType="H4" color={COLOR_SYSTEM.get("Gray")[700]}>
                  Bronze
                </Text>
              </RecruitTemplateListTitleRow>
            );
          }

          if (jobPostsPremium && jobPostsPremium.bronze.length > 0) {
            jobPostsPremium.bronze.forEach((jobPost: JobOfferInfo) => {
              posts.push(
                <PremiumCard
                  key={jobPost.id}
                  post={jobPost}
                  onClick={goToPost(jobPost, true)}
                />
              );
            });
          }

          if (jobPostsPremium.pharmacy.length) {
            posts.push(
              <RecruitTemplateListTitleRow
                key={"PHARMACY"}
                className={
                  jobPostsPremium.gold.length ||
                  jobPostsPremium.silver.length ||
                  jobPostsPremium.bronze.length
                    ? "mg-top"
                    : ""
                }
              >
                <img
                  src={`/images/list_pharmacy_medal.png`}
                  alt="product 이미지"
                  width={17}
                  height={20}
                />
                <Text textType="H4" color={COLOR_SYSTEM.get("Gray")[700]}>
                  Pharmacy
                </Text>
              </RecruitTemplateListTitleRow>
            );
          }

          if (jobPostsPremium && jobPostsPremium.pharmacy.length > 0) {
            jobPostsPremium.pharmacy.forEach((jobPost: JobOfferInfo) => {
              posts.push(
                <PremiumCard
                  key={jobPost.id}
                  post={jobPost}
                  onClick={goToPost(jobPost, true)}
                />
              );
            });
          }
          break;
        default:
          return null;
      }

      return posts;
    };

    // Event Handling
    const handleScrap = (offer) => () => {
      onScrap(offer);
      AdsUtil.show(AdsUtil.TYPE_INTERSTITIAL, {
        name: "구인공고스크랩",
        solution: AdsUtil.SOLUTION_ADMOB,
      });
    };

    const handleUnscrap = (offer) => () => {
      onUnscrap(offer);
    };

    const handlePremium = (offer) => () => {
      onPremium(offer);
    };

    const handleRaiseClick = (offer) => () => {
      log(
        LogLevel.UI_ACTION,
        "JobPostList: onRaise",
        offer,
        new Date(offer.raisedAt).toDateString(),
        new Date().toDateString()
      );
      if (
        new Date(offer.raisedAt).toDateString() !== new Date().toDateString()
      ) {
        selectedOffer.current = offer;
        setRaisePopup(true);
        hideTabbar();
      } else {
        setRaiseErrorPopup(true);
        hideTabbar();
      }
    };

    const handleFinalizeClick = (offer, type: JobListType) => () => {
      selectedOffer.current = offer;
      onPrepareFinalize(offer);
      setPassed([]);
      setFinishPopup(true);
      hideTabbar();
    };

    const handleChatLinkClick = () => {
      onChat();
      saveCurrentInfo();
    };

    const handleSettingLinkClick = () => {
      onSetting();
      setGlobal("jobpost-post-type", null);
      setGlobal("jobpost-all-list-scroll-position", null);
      setGlobal("jobpost-me-or-like-list-scroll-position", null);
    };

    const handleTabClick = (type: JobListType) => () => {
      setPostType(type);

      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "JOBPOST_OFFER_TAB",
        "구인구직_약사_리스트_탭_선택",
        {
          type: JobListType[type],
        }
      );
    };

    const handleFilterBottomSheetClick = (currentTab: Tab) => () => {
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "JOBPOST_SEARCH_CLICK",
        "구인구직_약사_메인_검색_선택",
        {
          currentTab,
        }
      );
      setCurrentTab(currentTab);
      setFilterBottomSheet(true);
      hideTabbar();
    };

    const handleSortClick = (order: PostSort) => () => {
      if (order === "distance" && !me.homeAddress) {
        setNoAddressPopup(true);
        hideTabbar();
        return;
      }

      const options =
        postType === JobListType.SEARCHED
          ? {
              ...jobPostsSearched.options,
              order,
            }
          : {
              ...jobPostsPharmacy.options,
              order,
            };

      onSearchChanged(options, postType);
      setSortFilterBottomSheet(false);
      displayTabbar();
      postListRef.current.scrollTop = 0;
    };

    // 스크롤
    const handlePostListScroll = (e: any) => {
      e.persist();
      if (scrollTopTimer) return;

      const { scrollTop } = e.target;

      // Debounce
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(() => {
        switch (postType) {
          case JobListType.PHARMACY:
            scrollPosition.current.pharmacy = scrollTop;
            break;
          case JobListType.BIZ:
            scrollPosition.current.biz = scrollTop;
            break;
          case JobListType.SEARCHED:
            scrollPosition.current.searched = scrollTop;
            break;
          case JobListType.MY:
            scrollPosition.current.me = scrollTop;
            break;
          case JobListType.MY_LIKE:
            scrollPosition.current.like = scrollTop;
            break;
          case JobListType.PREMIUM:
            scrollPosition.current.premium = scrollTop;
            break;
          default:
            break;
        }

        log(
          LogLevel.UI_EVENT,
          "JobPostList:handlePostListScroll ",
          scrollPosition.current
        );
      }, 200);
    };

    const handlePostListThrottleScroll = throttle(async (e: any) => {
      e.persist();

      const { clientHeight, scrollHeight, scrollTop } = e.target;

      if (
        postType !== JobListType.PREMIUM &&
        clientHeight + scrollTop >= scrollHeight * 0.7
      ) {
        onGetMorePosts(postType);
      }
    }, 300);

    const handleRegisterClick = () => {
      if (isHospital) {
        setRegisterPostHospitalPopup(true);
        hideTabbar();
      } else if (isOwner) {
        setWorkTypeSheet(true);
        hideTabbar();
      } else {
        setRegisterPostErrorPopup(true);
        hideTabbar();
      }
    };

    const handleWorkTypeSelectClick = (workType: JobOfferType) => () => {
      const inProgressPost = jobPostsMy.items.filter(
        (item) => offers[item.id].workType === workType
      );
      selectedWorkType.current = workType;
      setWorkTypeSheet(false);

      if (inProgressPost.length > 0) {
        setRequestRaisePopup(true);
      } else {
        history.push("/jobpost/register", { workType });
        displayTabbar();
        saveCurrentInfo();
      }
    };

    const handleToggleHideReadPost = () => {
      setUnreadOnly(!unreadOnly);
    };

    // 검색
    const handleBackClick = () => {
      setOnSearching(false);
      setPostType(getGlobal("currentPostType"));
    };

    const handleSearchClick = () => {
      setGlobal("currentPostType", postType);
      setOnSearching(true);
      saveCurrentInfo();
    };

    const getKeywords = async () => {
      try {
        const res = await onGetKeywords();
        setKeywordList(res.data ? [...res.data] : []);
      } catch (err) {
        throw new Error(err);
      }
    };

    const handleSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (postType === JobListType.SEARCHED) setOnSearching(true);
      setKeywordValue(e.target.value);
    };

    const searchKeyword = (keyword: string) => {
      document.querySelector("input").blur();

      try {
        setPostType(JobListType.SEARCHED);
        setOnSearching(false);
        onAddKeyword(keyword);
        if (keywordList.some((item) => item.keyword === keyword)) {
          setKeywordList([
            { keyword, startAt: new Date().toISOString() },
            ...keywordList.filter((item) => item.keyword !== keyword),
          ]);
        } else {
          setKeywordList([
            { keyword, startAt: new Date().toISOString() },
            ...keywordList.slice(0, 4),
          ]);
        }

        const options = {
          ...jobPostsSearched.options,
          query: keyword,
          unreadOnly: false,
        };
        onSearchChanged(options, JobListType.SEARCHED);
      } catch (err) {
        throw new Error(err);
      }
    };

    const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (!keywordValue.trim()) return;
      searchKeyword(keywordValue.trim());
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "JOBPOST_SEARCH",
        "구인구직_약사_메인_검색",
        {
          keyword: keywordValue.trim(),
          type: "입력",
        }
      );
    };

    const handleDeleteKeyword = (keyword: string = "") => (
      e: React.MouseEvent<HTMLButtonElement>
    ) => {
      e.stopPropagation();
      onDeleteKeyword(keyword);
      setKeywordList(
        keyword ? keywordList.filter((item) => item.keyword !== keyword) : []
      );
    };

    const handleSelectKeyword = (keyword: string) => (
      e: React.MouseEvent<HTMLLIElement>
    ) => {
      e.stopPropagation();

      setKeywordValue(keyword);
      searchKeyword(keyword);
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "JOBPOST_SEARCH",
        "구인구직_약사_메인_검색",
        {
          keyword,
          type: "선택",
        }
      );
    };

    const setScrollTop = () => {
      // console.log("scrollPosition", scrollPosition);
      // console.log(getGlobal("pharmacy-scroll-position"));
      // console.log(getGlobal("biz-scroll-position"));

      switch (postType) {
        case JobListType.PHARMACY:
          // postListRef.current.scrollTop = jobPostListScrollPosition.current.pharmacy || 0;
          postListRef.current.scrollTop =
            getGlobal("pharmacy-scroll-position") || 0;
          scrollPosition.current.pharmacy =
            getGlobal("pharmacy-scroll-position") || 0;
          break;
        case JobListType.BIZ:
          // postListRef.current.scrollTop = jobPostListScrollPosition.current.biz || 0;
          postListRef.current.scrollTop = getGlobal("biz-scroll-position") || 0;
          scrollPosition.current.biz = getGlobal("biz-scroll-position") || 0;

          break;
        case JobListType.MY:
          // postListRef.current.scrollTop = jobPostListScrollPosition.current.me || 0;
          postListRef.current.scrollTop = getGlobal("me-scroll-position") || 0;
          scrollPosition.current.me = getGlobal("me-scroll-position") || 0;
          break;
        case JobListType.MY_LIKE:
          // postListRef.current.scrollTop = jobPostListScrollPosition.current.like || 0;
          postListRef.current.scrollTop =
            getGlobal("like-scroll-position") || 0;
          scrollPosition.current.like = getGlobal("like-scroll-position") || 0;
          break;
        case JobListType.SEARCHED:
          // postListRef.current.scrollTop = jobPostListScrollPosition.current.searched || 0;
          postListRef.current.scrollTop =
            getGlobal("searched-scroll-position") || 0;
          scrollPosition.current.searched =
            getGlobal("searched-scroll-position") || 0;
          break;
        case JobListType.PREMIUM:
          postListRef.current.scrollTop =
            getGlobal("premium-scroll-position") || 0;
          scrollPosition.current.premium =
            getGlobal("premium-scroll-position") || 0;
          break;
        default:
          break;
      }
      scrollTopTimer = setTimeout(() => {
        switch (postType) {
          case JobListType.PHARMACY:
            // postListRef.current.scrollTop = jobPostListScrollPosition.current.pharmacy || 0;
            postListRef.current.scrollTo(
              0,
              getGlobal("pharmacy-scroll-position") || 0
            );
            scrollPosition.current.pharmacy =
              getGlobal("pharmacy-scroll-position") || 0;
            break;
          case JobListType.BIZ:
            // postListRef.current.scrollTop = jobPostListScrollPosition.current.biz || 0;
            postListRef.current.scrollTo(
              0,
              getGlobal("biz-scroll-position") || 0
            );
            scrollPosition.current.biz = getGlobal("biz-scroll-position") || 0;

            break;
          case JobListType.MY:
            // postListRef.current.scrollTop = jobPostListScrollPosition.current.me || 0;
            postListRef.current.scrollTo(
              0,
              getGlobal("me-scroll-position") || 0
            );
            scrollPosition.current.me = getGlobal("me-scroll-position") || 0;
            break;
          case JobListType.MY_LIKE:
            // postListRef.current.scrollTop = jobPostListScrollPosition.current.like || 0;
            postListRef.current.scrollTo(
              0,
              getGlobal("like-scroll-position") || 0
            );
            scrollPosition.current.like =
              getGlobal("like-scroll-position") || 0;
            break;
          case JobListType.SEARCHED:
            // postListRef.current.scrollTop = jobPostListScrollPosition.current.searched || 0;
            postListRef.current.scrollTo(
              0,
              getGlobal("searched-scroll-position") || 0
            );
            scrollPosition.current.searched =
              getGlobal("searched-scroll-position") || 0;
            break;
          case JobListType.PREMIUM:
            postListRef.current.scrollTo(
              0,
              getGlobal("premium-scroll-position") || 0
            );
            scrollPosition.current.premium =
              getGlobal("premium-scroll-position") || 0;
            break;
          default:
            break;
        }
        scrollTopTimer = null;
        log(
          LogLevel.UI_EVENT,
          "JobPostList:setScrollTop timeout",
          scrollPosition.current
        );
      }, 10);

      log(
        LogLevel.UI_EVENT,
        "JobPostList:setScrollTop ",
        scrollPosition.current
      );
    };

    const tabBarItems = useMemo(() => {
      let items = [];

      if (me.workType === UserWorkType.PHARMACY_OWNER) {
        items.push({
          active: postType === JobListType.MY,
          badge: false,
          onClick: handleTabClick(JobListType.MY),
          text: "나의공고",
        });
      }
      if (
        (ABTestUtil.isTest(ABTestFeature.UI_PHARMACY_PREMIUM) ||
          me.workType !== UserWorkType.PHARMACY_OWNER) &&
        jobPostsPremium.gold &&
        (jobPostsPremium.gold.length ||
          jobPostsPremium.silver.length ||
          jobPostsPremium.bronze.length)
      ) {
        items.push({
          active: postType === JobListType.PREMIUM,
          badge: false,
          onClick: handleTabClick(JobListType.PREMIUM),
          text: "💎 Premium",
        });
      }
      items.push({
        active: postType === JobListType.PHARMACY,
        badge: false,
        onClick: handleTabClick(JobListType.PHARMACY),
        text: "약국",
      });
      items.push({
        active: postType === JobListType.BIZ,
        badge: false,
        onClick: handleTabClick(JobListType.BIZ),
        text: "병원/제약사",
      });

      if (me.workType != UserWorkType.PHARMACY_OWNER) {
        items.push({
          active: postType === JobListType.MY_LIKE,
          badge: false,
          onClick: handleTabClick(JobListType.MY_LIKE),
          text: "관심공고",
        });
      }

      return items;
    }, [me, postType, jobPostsPremium]);

    useEffect(() => {
      displayTabbar();
      setWorkTypeSheet(false);
      setFilterBottomSheet(false);
    }, [lastVisited]);

    useEffect(() => {
      if (postListRef.current) {
        // setScrollTop();

        switch (postType) {
          case JobListType.PHARMACY:
            postListRef.current.scrollTop =
              scrollPosition.current.pharmacy || 0;
            break;
          case JobListType.BIZ:
            postListRef.current.scrollTop = scrollPosition.current.biz || 0;
            break;
          case JobListType.MY:
            postListRef.current.scrollTop = scrollPosition.current.me || 0;
            break;
          case JobListType.MY_LIKE:
            postListRef.current.scrollTop = scrollPosition.current.like || 0;
            break;
          case JobListType.SEARCHED:
            postListRef.current.scrollTop =
              scrollPosition.current.searched || 0;
            break;
          case JobListType.PREMIUM:
            postListRef.current.scrollTop = scrollPosition.current.premium || 0;
            break;
          default:
            break;
        }
      }
    }, [postType]);

    useEffect(() => {
      if (postType === JobListType.PHARMACY) {
        let setTimeOutId = null;

        if (
          jobPostsPharmacy &&
          jobPostsPharmacy.options.unreadOnly !== unreadOnly
        ) {
          setTimeOutId = setTimeout(() => {
            const options = {
              ...jobPostsPharmacy.options,
              unreadOnly: unreadOnly,
            };
            onSearchChanged(options, JobListType.PHARMACY);
          }, 200);
        }

        return () => {
          if (setTimeOutId) clearTimeout(setTimeOutId);
        };
      }

      if (postType === JobListType.BIZ) {
        let setTimeOutId = null;

        if (jobPostsBiz && jobPostsBiz.options.unreadOnly !== unreadOnly) {
          setTimeOutId = setTimeout(() => {
            const options = {
              ...jobPostsBiz.options,
              unreadOnly: unreadOnly,
            };
            onSearchChanged(options, JobListType.BIZ);
          }, 200);
        }

        return () => {
          if (setTimeOutId) clearTimeout(setTimeOutId);
        };
      }
    }, [unreadOnly]);

    useEffect(() => {
      if (!onSearching) {
        if (isMobile()) displayTabbar();
        setScrollTop();
      } else {
        // searchedListScrollTop.current = 0;
      }

      backKeyControl.pushListener(() => {
        log(
          LogLevel.UI_LIFECYCLE,
          "JobPostList:back key callback",
          onSearching
        );

        if (onSearching) {
          setOnSearching(false);
          return true;
        } else if (postType === JobListType.SEARCHED) {
          setPostType(getGlobal("jobpost-post-type"));
          return true;
        }
        return false;
      }, "jobpost");
    }, [onSearching]);

    useEffect(() => {
      const isIOS = getOS() === "ios";

      if (isMobile() && !isIOS) {
        keyboard ? hideTabbar() : displayTabbar();
      }
    }, [keyboard]);

    useEffect(() => {
      getKeywords();

      if (postListRef.current) {
        setScrollTop();
      }

      const isIOS = getOS() === "ios";

      if (!isIOS) {
        window.visualViewport.addEventListener("resize", () => {
          setKeyboard(window.visualViewport.height !== initialHeight);
        });
      }

      return () => {
        if (!isIOS) {
          window.visualViewport.removeEventListener("resize", () => {
            setKeyboard(window.visualViewport.height !== initialHeight);
          });
        }

        backKeyControl.popListener("jobpost");
      };
    }, []);

    return (
      <JobPostListWrapper>
        <Header>
          {onSearching || postType === JobListType.SEARCHED ? (
            <>
              <TopAppBar
                titleType="Search"
                leftButtons={[
                  <Button
                    color="Secondary"
                    icon="CaretLeft"
                    onClick={handleBackClick}
                    size="Medium"
                    type="Icon"
                    variant="Ghost"
                  />,
                ]}
                searchPlaceholder="원하시는 조건으로 검색해보세요"
                value={keywordValue}
                onChange={handleSearchValue}
                onClear={() => setKeywordValue("")}
                onSubmit={handleSearch}
                onClick={() => {
                  setOnSearching(true);
                }}
              />
            </>
          ) : (
            <TopAppBar
              title="구인구직"
              titleType="H1"
              rightButtons={[
                !isOwner && (
                  <Button
                    color="Quaternary"
                    icon="MagnifyingGlass Filled"
                    onClick={handleSearchClick}
                    size="Medium"
                    type="Icon"
                    variant="Ghost"
                  />
                ),
                <TopAppBarRight>
                  <Button
                    color="Quaternary"
                    icon="ChatFilled"
                    onClick={handleChatLinkClick}
                    size="Medium"
                    type="Icon"
                    variant="Ghost"
                    disabled={me.workType == UserWorkType.UNDERGRADUATE}
                    disabledColor={me.workType == UserWorkType.UNDERGRADUATE}
                  />
                  {chatUnreadMessageCount > 0 && <NewChatAlertBox />}
                </TopAppBarRight>,
                <Button
                  color="Quaternary"
                  icon="SettingFilled"
                  onClick={handleSettingLinkClick}
                  size="Medium"
                  type="Icon"
                  variant="Ghost"
                />,
              ]}
            />
          )}
          {!onSearching && postType !== JobListType.SEARCHED && (
            <TabBar
              size={"Medium"}
              items={tabBarItems}
              scrollable
              type="Underlined"
            />
          )}
        </Header>
        {/* {bannerView} */}
        <Main
          ref={postListRef}
          onScroll={(e) => {
            handlePostListScroll(e);
            handlePostListThrottleScroll(e);
          }}
          searching={onSearching}
          postType={postType}
        >
          {onSearching && (
            <>
              <div className="recent-searched__title">
                <Text
                  textType="Body1SemiBold"
                  color={COLOR_SYSTEM.get("Gray")[800]}
                >
                  최근검색어
                </Text>
                <Button
                  color="Tertiary"
                  size="Small"
                  type="Text"
                  variant="Ghost"
                  onClick={handleDeleteKeyword()}
                >
                  전체 삭제
                </Button>
              </div>
              <ul className="recent-searched__list">
                {keywordList.map((history) => (
                  <li
                    className="recent-searched__item"
                    key={history.startAt}
                    onClick={handleSelectKeyword(history.keyword)}
                  >
                    <Text
                      textType="Body1"
                      color={COLOR_SYSTEM.get("Gray")[700]}
                    >
                      {history.keyword}
                    </Text>
                    <Button
                      color="Quaternary"
                      size="Medium"
                      type="Icon"
                      icon="XFilled"
                      variant="Ghost"
                      onClick={handleDeleteKeyword(history.keyword)}
                    />
                  </li>
                ))}
              </ul>
            </>
          )}

          {!onSearching && (
            <>
              {(postType === JobListType.PHARMACY ||
                postType === JobListType.BIZ ||
                postType === JobListType.SEARCHED) && (
                <>
                  <Filter>
                    <div className="filter-box">
                      <Button
                        color="Secondary"
                        icon="PolygonDown"
                        onClick={handleFilterBottomSheetClick("location")}
                        right
                        size="Small"
                        type="IconWithText"
                        variant="Outlined"
                        className="bottom-sheet__button"
                      >
                        {getCurrentFiltered("location", getOptions(postType))}
                      </Button>

                      {(postType === JobListType.BIZ ||
                        postType === JobListType.SEARCHED) && (
                        <Button
                          color="Secondary"
                          icon="PolygonDown"
                          onClick={handleFilterBottomSheetClick("companyType")}
                          right
                          size="Small"
                          type="IconWithText"
                          variant="Outlined"
                          className="bottom-sheet__button"
                        >
                          {getCurrentFiltered(
                            "companyType",
                            getOptions(postType)
                          )}
                        </Button>
                      )}

                      {postType === JobListType.PHARMACY ||
                      (postType === JobListType.BIZ &&
                        jobPostsBiz.options.companyType !== undefined) ||
                      (postType === JobListType.SEARCHED &&
                        jobPostsSearched.options.companyType !== undefined) ? (
                        <Button
                          color="Secondary"
                          icon="PolygonDown"
                          onClick={handleFilterBottomSheetClick("type")}
                          right
                          size="Small"
                          type="IconWithText"
                          variant="Outlined"
                          className="bottom-sheet__button"
                        >
                          {getCurrentFiltered("type", getOptions(postType))}
                        </Button>
                      ) : null}
                    </div>
                    <div className="filter-box space-between">
                      <Button
                        color="Tertiary"
                        icon="Swap Vertical"
                        onClick={() => {
                          setSortFilterBottomSheet(true);
                          hideTabbar();
                        }}
                        left
                        size="Small"
                        type="IconWithText"
                        variant="Ghost"
                      >
                        {getSelectedSort()}
                      </Button>
                      {me.workType !== UserWorkType.PHARMACY_OWNER &&
                        postType !== JobListType.SEARCHED && (
                          // <Switch
                          //   className="hide-post-switch"
                          //   size="Small"
                          //   isActive={unreadOnly}
                          //   label="읽은 글 숨기기"
                          //   labelPosition="Right"
                          //   onClick={handleToggleHideReadPost}
                          // />
                          <ToggleButton
                            color="Skyblue"
                            size="Small"
                            variant="Ghost"
                            type="IconWithText"
                            icon="Check Circle"
                            left
                            active={unreadOnly}
                            onClick={handleToggleHideReadPost}
                            style={{ float: "right" }}
                          >
                            안읽은공고
                          </ToggleButton>
                        )}
                    </div>
                  </Filter>
                </>
              )}

              {postType === JobListType.MY && (
                <img
                  src="/images/banner_jobpost_premium.png"
                  style={{ width: "100%" }}
                  onClick={() => onPremium(null)}
                />
              )}

              <PostList
                postType={postType}
                noSearchedPost={
                  jobPostsPharmacy && jobPostsPharmacy.items.length === 0
                }
                noMyLikePost={
                  jobPostsMyLike && jobPostsMyLike.items.length === 0
                }
                noMyPost={jobPostsMy && jobPostsMy.items.length === 0}
              >
                {renderPosts()}
              </PostList>
            </>
          )}
        </Main>

        {!onSearching && (
          <>
            {me.workType != UserWorkType.UNDERGRADUATE && (
              <FixedButton center>
                <FloatingButton
                  color="Primary"
                  icon="Pencil"
                  right
                  size="Large"
                  type="IconWithText"
                  onClick={handleRegisterClick}
                >
                  공고 등록
                </FloatingButton>
              </FixedButton>
            )}
            <FixedButton right>
              <FloatingButton
                color="Tertiary"
                icon="Refresh"
                onClick={handleTopClick}
                size="Large"
                type="Icon"
              />
            </FixedButton>
            <FixedButton left>
              <FloatingButton
                color="Secondary"
                onClick={handleChannelTalk}
                size="Large"
                type="Text"
              >
                문의
              </FloatingButton>
            </FixedButton>
          </>
        )}

        {postType === JobListType.PHARMACY ||
        postType === JobListType.BIZ ||
        postType === JobListType.SEARCHED ? (
          <>
            {/* 필터 Bottom Sheet */}
            <BottomSheet
              active={filterBottomSheet}
              onClose={() => {
                setFilterBottomSheet(false);
                displayTabbar();
              }}
              height={"75vh"}
              maxHeight={"550px"}
              className="no-padding"
            >
              <LocationBottomSheetModal
                me={me}
                currentTab={currentTab}
                postType={postType}
                jobPosts={(() => {
                  switch (postType) {
                    case JobListType.PHARMACY:
                      return jobPostsPharmacy;
                    case JobListType.BIZ:
                      return jobPostsBiz;
                    case JobListType.SEARCHED:
                      return jobPostsSearched;
                    default:
                      break;
                  }
                })()}
                active={filterBottomSheet}
                setCurrentTab={setCurrentTab}
                onClose={() => {
                  setFilterBottomSheet(false);
                  displayTabbar();
                }}
                onDone={(options) => {
                  switch (postType) {
                    case JobListType.PHARMACY:
                      onSearchChanged(
                        { ...jobPostsPharmacy.options, ...options, query: "" },
                        postType
                      );
                      break;
                    case JobListType.BIZ:
                      onSearchChanged(
                        { ...jobPostsBiz.options, ...options, query: "" },
                        postType
                      );
                      break;
                    case JobListType.SEARCHED:
                      onSearchChanged(
                        {
                          ...jobPostsSearched.options,
                          ...options,
                          query: keywordValue,
                        },
                        postType
                      );
                      break;
                    default:
                      break;
                  }

                  postListRef.current.scrollTop = 0;
                }}
              />
            </BottomSheet>
          </>
        ) : null}

        <ListSortFilterBottomSheet
          active={sortFilterBottomSheet}
          onClose={() => {
            setSortFilterBottomSheet(false);
            displayTabbar();
          }}
          onSortClick={handleSortClick}
          sort={
            postType === JobListType.SEARCHED
              ? jobPostsSearched.options.order
              : postType === JobListType.PHARMACY
              ? jobPostsPharmacy.options.order
              : jobPostsBiz.options.order
          }
        />

        {/* 끌어올리기 팝업 */}
        {raisePopup && (
          <Popup
            onClose={() => setRaisePopup(false)}
            children={
              <>
                <PullUpModalMain>
                  <Text
                    element="h4"
                    textType="H4"
                    color={COLOR_SYSTEM.get("Gray")[800]}
                  >
                    이 공고를 끝어올릴까요?
                  </Text>
                  <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[600]}>
                    공고 리스트 최상단으로 이동합니다.
                    <br />
                    끌어올리기는 하루 한번만 가능하며
                    <br />
                    내일 00:00에 다시 할 수 있어요
                  </Text>
                </PullUpModalMain>
                <PullUpModalFooter>
                  <Button
                    color="Secondary"
                    size="Large"
                    type="Text"
                    variant="Tinted"
                    onClick={() => {
                      setRaisePopup(false);
                      displayTabbar();
                    }}
                  >
                    취소
                  </Button>
                  <Button
                    color="Primary"
                    size="Large"
                    type="Text"
                    variant="Contained"
                    onClick={() => {
                      onRaise(selectedOffer.current);
                      setRaisePopup(false);
                      AdsUtil.show(AdsUtil.TYPE_REWARD, {
                        name: "구인공고끌어올리기",
                      });
                      displayTabbar();
                    }}
                  >
                    끌어올리기
                  </Button>
                </PullUpModalFooter>
              </>
            }
          />
        )}

        {/* 끌어올리기 에러 팝업 */}
        {raiseErrorPopup && (
          <Popup
            onClose={() => {
              setRaiseErrorPopup(false);
              displayTabbar();
            }}
            children={
              <>
                <PullUpErrorModalMain>
                  <Icon
                    width={50}
                    height={50}
                    name={"Exclamation Mark Filled"}
                    fill={COLOR_SYSTEM.get("Red")[300]}
                  />
                  <Text
                    element="h4"
                    textType="H4"
                    color={COLOR_SYSTEM.get("Gray")[800]}
                  >
                    내일 다시 시도해주세요
                  </Text>
                  <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[600]}>
                    끌어올리기는 하루 한번만 가능하며
                    <br />
                    내일 00:00에 다시 할 수 있어요
                  </Text>
                </PullUpErrorModalMain>
                <PullUpErrorModalFooter>
                  <Button
                    color="Secondary"
                    size="Large"
                    type="Text"
                    variant="Tinted"
                    onClick={() => {
                      setRaiseErrorPopup(false);
                      displayTabbar();
                    }}
                  >
                    닫기
                  </Button>
                </PullUpErrorModalFooter>
              </>
            }
          />
        )}

        {/* 공고 종료 팝업 */}
        {finishPopup && (
          <FinishJobPostPopup
            post={selectedOffer.current}
            passed={passed}
            setPassed={setPassed}
            onClose={() => {
              setFinishPopup(false);
            }}
          />
        )}

        {/* 주소 입력 안하고 거리순 필터시 에러 팝업 */}
        {noAddressPopup && (
          <Popup
            onClose={() => {
              setNoAddressPopup(false);
              displayTabbar();
            }}
            children={
              <>
                <PullUpErrorModalMain>
                  <Icon
                    width={60}
                    height={60}
                    name={"MapPin"}
                    fill={COLOR_SYSTEM.get("Skyblue")[400]}
                  />
                  <Text
                    element="h4"
                    textType="H4"
                    color={COLOR_SYSTEM.get("Gray")[800]}
                  >
                    주소를 입력하면 구인공고를
                    <br />
                    가까운 거리순으로 볼 수 있어요
                  </Text>
                </PullUpErrorModalMain>
                <PullUpErrorModalFooter>
                  <Button
                    color="Secondary"
                    size="Medium"
                    type="Text"
                    variant="Tinted"
                    onClick={() => {
                      setNoAddressPopup(false);
                      displayTabbar();
                    }}
                  >
                    다음에 하기
                  </Button>
                  <Button
                    color="Primary"
                    size="Medium"
                    type="Text"
                    variant="Contained"
                    onClick={() => {
                      setNoAddressPopup(false);
                      handleSettingLinkClick();
                      displayTabbar();
                    }}
                  >
                    주소 입력하기
                  </Button>
                </PullUpErrorModalFooter>
              </>
            }
          />
        )}

        {/* 근무약사가 공고 등록하기 눌렀을 때 에러 팝업 */}
        {registerPostErrorPopup && (
          <Popup
            onClose={() => {
              setRegisterPostErrorPopup(false);
              displayTabbar();
            }}
            children={
              <>
                <PullUpErrorModalMain>
                  <Icon
                    width={60}
                    height={60}
                    name={"Exclamation Mark Filled"}
                    fill={COLOR_SYSTEM.get("Red")[300]}
                  />
                  <Text textType="H4" color={COLOR_SYSTEM.get("Gray")[800]}>
                    구인공고를 등록하려면
                    <br />
                    근무유형을{" "}
                    <Text
                      textType="H4"
                      color={COLOR_SYSTEM.get("Skyblue")[400]}
                    >
                      개국약사
                    </Text>
                    로 수정해주세요
                  </Text>
                  <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[600]}>
                    현재 근무 유형 :{" "}
                    <Text
                      textType="Body1Bold"
                      color={COLOR_SYSTEM.get("Gray")[600]}
                    >
                      {UserWorkTypeName[me.workType]}
                    </Text>
                  </Text>
                </PullUpErrorModalMain>
                <PullUpErrorModalFooter>
                  <Button
                    color="Secondary"
                    size="Medium"
                    type="Text"
                    variant="Tinted"
                    onClick={() => {
                      setRegisterPostErrorPopup(false);
                      displayTabbar();
                    }}
                  >
                    취소
                  </Button>
                  <Button
                    color="Primary"
                    size="Medium"
                    type="Text"
                    variant="Contained"
                    onClick={() => {
                      setRegisterPostErrorPopup(false);
                      handleSettingLinkClick();
                      displayTabbar();
                    }}
                  >
                    수정하러 가기
                  </Button>
                </PullUpErrorModalFooter>
              </>
            }
          />
        )}
        {registerPostHospitalPopup && (
          <Popup
            onClose={() => {
              setRegisterPostHospitalPopup(false);
              displayTabbar();
            }}
            children={
              <>
                <PullUpErrorModalMain>
                  <Icon
                    width={60}
                    height={60}
                    name={"Exclamation Mark Filled"}
                    fill={COLOR_SYSTEM.get("Red")[300]}
                  />
                  <Text textType="H4" color={COLOR_SYSTEM.get("Gray")[800]}>
                    병원/제약사 구인공고는 <br />
                    <Text
                      textType="H4"
                      color={COLOR_SYSTEM.get("Skyblue")[400]}
                    >
                      약문약답 법인구인구직
                    </Text>
                    <br />
                    사이트에서 등록이 가능합니다.
                  </Text>
                  <Text
                    textType="Body1Bold"
                    color={COLOR_SYSTEM.get("Skyblue")[400]}
                    onClick={onGotoBiz}
                  >
                    https://biz.ymyd.co.kr/
                  </Text>
                  <Text textType="H4" color={COLOR_SYSTEM.get("Gray")[800]}>
                    아래 버튼을 눌러 채용 담당자에게 <br />
                    안내해주세요.
                  </Text>
                </PullUpErrorModalMain>
                <PullUpErrorModalFooter>
                  <Button
                    color="Secondary"
                    size="Medium"
                    type="Text"
                    variant="Tinted"
                    onClick={() => {
                      setRegisterPostHospitalPopup(false);
                      displayTabbar();
                    }}
                  >
                    취소
                  </Button>
                  <Button
                    color="Primary"
                    size="Medium"
                    type="Text"
                    variant="Contained"
                    onClick={() => {
                      setRegisterPostHospitalPopup(false);
                      onMessageBiz();
                      displayTabbar();
                    }}
                  >
                    담당자에게 안내하기
                  </Button>
                </PullUpErrorModalFooter>
              </>
            }
          />
        )}

        {/* 끌어올리기 제안 팝업 */}
        {requestRaisePopup && (
          <RequestRaisePopup
            selectedWorkTypeName={JobOfferName[selectedWorkType.current]}
            onClose={() => {
              setRequestRaisePopup(false);
              displayTabbar();
            }}
            onCreate={() => {
              setRequestRaisePopup(false);
              history.push("/jobpost/register", {
                workType: selectedWorkType.current,
              });
              displayTabbar();
              saveCurrentInfo();
            }}
            onRaise={() => {
              setRequestRaisePopup(false);
              setPostType(JobListType.MY);
              setGlobal("jobpost-me-or-like-list-scroll-position", 0);
              postListRef.current.scrollTop = 0;
              displayTabbar();
            }}
          />
        )}

        {/* 근무 유형 Bottom Sheet */}
        <WorkTypeSelectBottomSheet
          active={workTypeSheet}
          onClose={() => {
            setWorkTypeSheet(false);
            displayTabbar();
          }}
          onSelect={handleWorkTypeSelectClick}
        />
      </JobPostListWrapper>
    );
  } catch (error) {
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "JOBPOST_List_Template_Error",
      "에러",
      {
        page: "JobPostListTemplate",
        errorMessage: error,
      }
    );
  }
};

export default withRouter(JobPostList);
